<template>
    <div>
        <div class="table-page-title">
            <div>
                <el-form :model="searchForm" class="search-form" :inline="true">

                    <el-form-item label="" prop="orderNo">
                        <el-input class="small-input" v-model="searchForm.orderNo" placeholder="订单号"></el-input>
                    </el-form-item>

                    <el-form-item label="" prop="startTime">
                        <el-date-picker class="small-input small-input-date"
                                v-model="searchForm.startTime"
                                type="datetime"
                                :value-format="this.formatDate"
                                placeholder="申请时间起">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="" prop="entTime">
                        <el-date-picker class="small-input small-input-date"
                                v-model="searchForm.entTime"
                                type="datetime"
                                :value-format="this.formatDate"
                                placeholder="申请时间止">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="" prop="userName">
                        <el-input class="small-input" v-model="searchForm.userName" placeholder="用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="telephone">
                        <el-input class="small-input" v-model="searchForm.telephone" placeholder="手机号"></el-input>
                    </el-form-item>

                    <el-form-item label="" prop="status">
                        <el-select clearable class="small-input" v-model="searchForm.status" placeholder="是否已退">
                            <el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="search-form-btn">
                <el-button size="small" icon="el-icon-search" type="primary" @click="search()">搜 索</el-button>
                <el-button size="small" icon="el-icon-refresh" type="primary" @click="reset()">重 置</el-button>
            </div>
        </div>
        <div class="table-container">
            <el-table
                    class="table"
                    height="100%"
                    v-loading="tableLoading"
                    :data="tableData">
                <el-table-column
                        label="订单号"
                        prop="orderNo"
                        fixed="left"
                        align="center"
                        header-align="center"
                        width="160"
                >
                </el-table-column>
                <el-table-column
                        label="用户名"
                        prop="userName"
                        align="center"
                        header-align="center"
                >
                </el-table-column>
                <el-table-column
                        label="手机号"
                        prop="telephone"
                        width="120"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                        label="设备名称"
                        prop="deviceName"
                        align="center"
                        width="200"
                        show-overflow-tooltip="true"
                        header-align="center"
                >
                </el-table-column>
                <el-table-column
                        label="申请时间"
                        prop="createTime"
                        width="180"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                </el-table-column>
                <el-table-column
                        label="申请内容"
                        prop="content"
                        align="center"
                        width="180"
                        show-overflow-tooltip="true"
                        header-align="center"
                >
                </el-table-column>
                <el-table-column
                        label="退款类型"
                        prop="refundType"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                    <template slot-scope="{row}">
                        {{ row.refundType === 0 ? '押金' : row.refundType === 1 ? '租金' : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="退款金额"
                        prop="amount"
                        align="center"
                        header-align="center"
                >
                </el-table-column>
                <el-table-column
                        label="是否已退"
                        prop="status"
                        align="center"
                        header-align="center"
                        :formatter="commonFormatter"
                >
                    <template slot-scope="{row}">
                        {{ row.status === 0 ? '否' : row.status === 1 ? '是' : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        width="100"
                        fixed="right"
                        align="center"
                        header-align="center"
                        label="操作">
                    <template slot-scope="{row}">
                        <el-button :disabled="row.status !== 0" type="text" @click="goConfirm(row)">确认</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination
                class="top_10 right right_10"
                :current-page="pageNum"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    import tableUse from "@/mixins/tableUse";

    export default {
        name: "RefundWorkList",
        mixins: [tableUse],
        data() {
            return {
                searchForm: {
                    orderNo:null,
                    startTime:null,
                    entTime:null,
                    userName:null,
                    telephone:null,
                    status:null
                },
                statusList:[
                    {
                        value:0,
                        label:'否'
                    },
                    {
                        value:1,
                        label:'是'
                    }
                ],
                refundLoading:false,
                tableData: [],
                formatDate:'yyyy-MM-dd HH:mm:ss'
            }
        },
        methods: {
            reset() {
                this.searchForm = {
                    orderNo:null,
                    startTime:null,
                    entTime:null,
                    userName:null,
                    telephone:null,
                    status:null
                };
                this.search();
            },
            getTableData() {
                this.tableLoading = true;
                this.searchForm.pageNum = this.pageNum;
                this.searchForm.pageSize = this.pageSize;
                this.searchForm.workType = 1;
                this.$axios.post("/sys-api/order-api/getWorkList", this.searchForm, (res) => {
                    this.tableLoading = false;
                    if (res.code === '100') {
                        this.total = res.data.total;
                        this.tableData = res.data.list;
                    }
                })
            },
            goConfirm(row){
                this.$confirm('确定退款吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.refundLoading = true;
                    this.$axios.post("/sys-api/order-api/submitRefund",row,(res) => {
                        this.refundLoading = false;
                        if(res.code === '100'){
                            this.$message.success("操作成功")
                            this.getTableData();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        },
        mounted() {
            this.getTableData();
        }
    }
</script>

<style scoped lang="scss">
    .table-container {
        height: calc(100% - 155px);
    }
    .small-input-date{
        width: 100%;
    }
</style>
